
import { PropType } from 'vue'
import { CourseSeries } from 'momai'
export default {
  props: {
    courseSeriesList: {
      type: Array as PropType<CourseSeries[]>,
      default: () => []
    },
    columnCum: {
      type: Number,
      default: 2
    }
  },
  setup: () => {
    return {
      formatPrice: (p: string) => {
        return (Number(p) / 100).toFixed(2)
      }
    }
  }
}
